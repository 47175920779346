/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React, { FormEvent, useEffect, useState } from "react"
 import { PageProps } from "gatsby"
import { Container, Row, Col, Image, Button, Form } from "react-bootstrap"
import { FaYoutube, FaInstagram, FaDiscord, FaTwitter } from 'react-icons/fa';
import { Contract } from "web3-eth-contract"
import Navbar from "../navBar2"
import FooterLogo from "../../images/FooterLogo.png"
import "bootstrap/dist/css/bootstrap.min.css"
import "./layout.css"

const styles = require("./layout.module.scss")

// TODO (Ben): Update prop types
type LayoutProps = {
  children: React.ReactNode
  pageProps: PageProps,
  accountAddress: string | null,
  contract: Contract | null,
  getAndSetAccount: Function | null,
  shouldShowNavbar: boolean,
  shouldShowFooter: boolean,
}
const Layout: React.FC<LayoutProps> = (props) => {
  const {
    children,
    pageProps,
    accountAddress,
    contract,
    getAndSetAccount,
    shouldShowNavbar,
    shouldShowFooter,
  } = props;
  const { uri = "/" } = pageProps

  const pageInfo = {
    curPage: uri,
  }

  const [email, setEmail] = useState("");
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetch("https://bionicoutliertribe.us5.list-manage.com/subscribe/post?u=7e9494e23fdd770cfab2ac693&id=5e9682b2b2", {
      "body": new FormData(e.currentTarget),
      "method": "POST",
      "mode": "no-cors"
    })
    .then(
      (result) => {
        setEmailSubmitted(true);
      },
      (error) => {
        // Do nothing
      }
    );;
  };

  const footerEmailContent =
    emailSubmitted
      ? <p className={styles.emailSuccessText}>Thanks for subscribing.</p>
      : <Form
          name="mc-embedded-subscribe-form"
          method="GET"
          onSubmit={onSubmit}
          className="d-flex align-items-center justify-content-center"
        >
          <Form.Group as={Col} controlId="formGridEmail" className="d-flex align-items-center justify-content-center">
            <Form.Control
              type="email"
              value={email}
              className={styles.emailInput}
              name="EMAIL"
              placeholder="Email Address"
              required
              onChange={(e) => {
                e.preventDefault();
                setEmail(e.target.value);
              }}
            />
            <Button type="submit" className={styles.subscribeButton}>→</Button>
          </Form.Group>
        </Form>

  return (
    <>
      {shouldShowNavbar && (
        <Container fluid className="px-0 main">
          <Navbar pageInfo={pageInfo} accountAddress={accountAddress} contract={contract} getAndSetAccount={() => getAndSetAccount()} />
        </Container>
      )}
      <main>{children}</main>
      {shouldShowFooter && (
        <Container fluid>
          <Row className="d-flex align-items-center justify-content-center">
            <footer className={styles.footer}>
              <div className={`d-flex flex-column justify-content-center col-12 col-sm-12 col-md-12 col-lg-3 ${styles.subscribeAlignment}`}>
                <p className={styles.emailListText}>Subscribe</p>
                {footerEmailContent}
              </div>
              <div className="col-0 col-lg-1"></div>
              <div className="d-flex flex-column align-items-center justify-content-center col-12 col-sm-12 col-md-12 col-lg-4">
                <Image src={FooterLogo} className={`col-12 col-sm-12 col-md-12 col-lg-4 ${styles.alternateBotLogo}`} />
              </div>
              <p className={`d-flex align-items-center col-12 col-sm-12 col-md-12 col-lg-4 ${styles.footerSocialIcons}`}>
                <a href="https://www.youtube.com/channel/UCGuQMgV_hLcxZPSjN7_WUxQ/featured" target="_blank">
                  <FaYoutube className={styles.footerSocialIcon} color={"white"} size={20}/>
                </a>
                <a href="https://www.instagram.com/bionicoutliertribe/" target="_blank">
                  <FaInstagram className={styles.footerSocialIcon} color={"white"} size={20} />
                </a>
                <a href="https://www.discord.gg/7eTqwxn6nW" target="_blank">
                  <FaDiscord className={styles.footerSocialIcon} color={"white"} size={20} />
                </a>
                <a href="https://twitter.com/botnfts" target="_blank">
                  <FaTwitter className={`${styles.footerSocialIcon} ${styles.lastFooterSocialIcon}`} color={"white"} size={20}/>
                </a>
              </p>
            </footer>
          </Row>
        </Container>
      )}
    </>
  )
}

export default Layout
