import React, { useEffect, useState } from "react"
import { scroller } from "react-scroll";
import { Container, Navbar, Nav, Image } from "react-bootstrap"
import { Contract } from "web3-eth-contract"
import axios from "axios"
import { FaYoutube, FaInstagram, FaDiscord, FaTwitter } from 'react-icons/fa';
import OpenseaLogo from "../../images/OpenseaLogo.png"
import BOTLogo from "../../images/BOTLogo.png"

const styles = require("./navBar.module.scss")

type NavBar2Props = {
  pageInfo: {
    curPage: string,
  },
  accountAddress: string | null,
  contract: Contract,
  getAndSetAccount: Function,
}

const Navbar2: React.FC<NavBar2Props> = ({ pageInfo = { curPage: "/" }, accountAddress, contract, getAndSetAccount }) => {
  // TODO (Ben): Possibly remove this logic if Will and Sean are not interested in a "My BOTS" page
  const [ownerNoobsMetadata, setOwnerNoobsMetadata] = useState<Object[]>([])

  useEffect(() => {
    if (accountAddress && contract) {
      contract.methods.listTokensForOwner(accountAddress).call().then(async (ownerNoobs: number[]) => {
        const noobsMetadata = await Promise.all(ownerNoobs.map(async (tokenId) => {
          const tokenUri = await contract.methods.tokenURI(tokenId).call();
          if (tokenUri) {
            const { data: metaData } = await axios.get(tokenUri)
            return { ...metaData, tokenId }
          }
          return { tokenId }
        }))

        setOwnerNoobsMetadata(noobsMetadata);
      }).catch(e => {
        // TODO (Ben): Improve error handling
        console.log(e);
      })
    }
  }, [accountAddress, contract]);

  return (
    <>
      <Navbar collapseOnSelect variant="dark" expand="lg" fixed="top" className={styles.navbar}>
        <Container>
          <Navbar.Brand>
            <Nav.Link
              className={styles.brandText}
              href="/home/#"
            >
              <span style={{color: "white", fontWeight: "bold"}}>BIONIC OUTLIER TRIBE</span>
            </Nav.Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
            <Nav className="d-flex align-items-center justify-content-center flex-row">
              <div
                className={styles.navLink}
                style={{color: "white"}}
                onClick={() => scroller.scrollTo("mint", {
                  smooth: true,
                  offset: -80,
                  duration: 100,
                })}
              >
                Buy
              </div>
              <div
                className={styles.navLink}
                style={{color: "white"}}
                onClick={() => scroller.scrollTo("about", {
                  smooth: true,
                  offset: -80,
                  duration: 100,
                })}
              >
                About
              </div>
              <div
                className={styles.navLink}
                style={{color: "white"}}
                onClick={() => scroller.scrollTo("roadmap", {
                  smooth: true,
                  offset: -80,
                  duration: 100,
                })}
              >
                Roadmap
              </div>
              <div
                className={styles.navLink}
                style={{color: "white"}}
                onClick={() => scroller.scrollTo("team", {
                  smooth: true,
                  offset: -80,
                  duration: 100,
                })}
              >
                Team
              </div>
            </Nav>
            <Nav className="d-flex align-items-center justify-content-center flex-row">
              <a href="https://www.youtube.com/channel/UCGuQMgV_hLcxZPSjN7_WUxQ/featured" target="_blank">
                <FaYoutube className={styles.navSocialIcon} color={"white"} size={20}/>
              </a>
              <a href="https://www.instagram.com/bionicoutliertribe/" target="_blank">
                <FaInstagram className={styles.navSocialIcon} color={"white"} size={20} />
              </a>
              <a href="https://www.discord.gg/7eTqwxn6nW" target="_blank">
                <FaDiscord className={styles.navSocialIcon} color={"white"} size={20} />
              </a>
              <a href="https://twitter.com/botnfts" target="_blank">
                <FaTwitter className={`${styles.navSocialIcon} ${styles.lastNavSocialIcon}`} color={"white"} size={20}/>
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Navbar2
